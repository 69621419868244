<template>
  <div class="bgf8 p-b-30 main">
    <div class="w1200">
      <div class="padc16 font14 color999 flex-left">
        <img src="@/assets/images/icon_27.png" alt="" />
        <span class="m-l-8">当前位置</span>
        <span class="pad8">/</span>
        医知学
        <span class="pad8">/</span>
        <span class="colorff9">课程详情</span>
      </div>
      <div class="m-box m-head">
        <div class="flex-left mh-top">
          <div class="mht-video">
            <video
              id="videoPlay"
              controls
              muted:false
              width="840"
              height="480"
              :poster="queryList.coverImg || coverImg"
              data-setup="{}"
              :src="vUrl"
              @timeupdate="playChange"
              @play="startPlay"
              @pause="pausePlay"
            >
              <source />
            </video>
          </div>
          <div class="flex-1 pad20 colorfff mht-con">
            <div
              class="padc16 hand"
              v-for="(item, i) in videoList"
              :key="i"
              @click="videoBtn(item)"
            >
              <div class="flex-between">
                <div class="font16">{{ item.videoName }}</div>
                <div v-if="item.free == 1 ? true : false" class="color22b">
                  免费
                </div>
                <img v-else src="@/assets/images/icon_32.png" alt="" />
              </div>
              <div class="m-t-12 flex-between color999">
                <div class="flex-left font12">
                  <img src="@/assets/images/icon_17.png" alt="" />
                  <span class="m-l-4"
                    >时长：{{ formatTime(item.duration) }}</span
                  >
                  <span class="pad4">|</span>
                  <span v-if="item.playTime">
                    上次播放{{ item.playTime }}
                  </span>
                  <span v-else> 未学习</span>
                </div>
                <div v-if="item.videoId == videoId && isPlay" class="flex-left">
                  <img src="@/assets/images/icon_30.png" alt="" />
                  <span class="m-l-8">播放中</span>
                </div>
                <div v-else class="flex-left">
                  <img src="@/assets/images/icon_31.png" alt="" />
                  <span class="m-l-8">待播放</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-30 flex-between">
          <div>
            <div class="font24 color333 weight">
              {{ queryList.title }}
            </div>
            <div class="flex-left m-t-10 font14 color999">
              <div class="flex-left">
                <img src="@/assets/images/icon_31.png" alt="" />
                <span class="m-l-10">共有{{ queryList.videoCount }}节课时</span>
              </div>
              <div class="flex-left m-l-48">
                <img src="@/assets/images/icon_28.png" alt="" />
                <span class="m-l-10"
                  >已播放{{ queryList.realPlayCount }}次</span
                >
              </div>
              <div class="flex-left m-l-100">
                <span class="font18 weight colorff9"
                  >¥ {{ queryList.salePrice }}</span
                >
                <span class="m-l-10 text-del colorccc"
                  >¥ {{ queryList.markPrice }}</span
                >
              </div>
            </div>
          </div>

          <router-link :to="{ name: 'OrderInfo', query: queryList }"
            ><div
              v-if="couList.free == 0 && pay == 0"
              class="flex-cen colorfff font18 hand mh-btn"
            >
              立即购买
            </div></router-link
          >
        </div>
      </div>
      <div class="m-t-20 flex-left font14 color999 m-con">
        <div class="pad20 mc-left m-box">
          <div class="flex-left font20 p-t-10 mcr-border">
            <div
              class="padc16 pad30 hand m-l-20 po-rela"
              :class="curMenu == inx ? 'color-ffa weight mcl-ac' : ''"
              v-for="(item, inx) in menu"
              :key="item"
              @click="curMenu = inx"
            >
              {{ item }}
            </div>
          </div>
          <div
            v-if="curMenu == 0 ? true : false"
            v-html="couList.description"
          ></div>
          <div v-else class="flex-1 pad20 colorfff mht-con">
            <div
              class="padc16 hand"
              v-for="(item, i) in videoList"
              :key="i"
              @click="videoBtn(item)"
            >
              <div class="flex-between">
                <div class="font16 color666">
                  {{ item.videoName }}
                </div>
                <div v-if="item.free == 1 ? true : false" class="color22b">
                  免费
                </div>
                <img v-else src="@/assets/images/icon_32.png" alt="" />
              </div>
              <div class="m-t-12 flex-between color999">
                <div class="flex-left font12">
                  <img src="@/assets/images/icon_17.png" alt="" />
                  <span class="m-l-4"
                    >时长：{{ formatTime(item.duration) }}</span
                  >
                  <span class="pad4">|</span>
                  <!-- 有播放时间 -->
                  <span v-if="item.playTime">
                    上次播放{{ item.playTime }}
                  </span>
                  <span v-else> 未学习</span>
                </div>
                <div v-if="item.videoId == videoId && isPlay" class="flex-left">
                  <img src="@/assets/images/icon_30.png" alt="" />
                  <span class="m-l-8">播放中</span>
                </div>
                <div v-else class="flex-left">
                  <img src="@/assets/images/icon_31.png" alt="" />
                  <span class="m-l-8">待播放</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="m-box m-l-20 flex-1 pad18 padc24 mc-right">
          <div class="flex-between p-b-16 mcr-border">
            <div class="font20 color333 weight">讲师介绍</div>
            <div
              class="flex-left color999 font12"
              @click="lookBtn(couList.teacherId)"
            >
              <span class="hand">查看主页</span>
              <img src="@/assets/images/icon_39.png" alt="" />
            </div>
          </div>
          <div class="m-t-20 flex-left">
            <div class="img70">
              <img class="coimg" :src="couList.headImg" alt="" />
            </div>
            <div class="m-l-20">
              <div class="font18 color333 weight">{{ couList.teacher }}</div>
              <div class="m-t-10">{{ couList.label }}</div>
            </div>
          </div>
          <div class="m-t-16 lh24 p-b-20 mcr-border">
            {{ couList.simpleContent }}
          </div>
          <div class="font20 color333 weight m-t-14">他的课程</div>
          <div>
            <CourseItem
              class="m-t-18"
              imgw="320"
              imgh="200"
              :isShowDesc="false"
              :coverImg="couList.headImg"
            ></CourseItem>
          </div>
        </div>
      </div>
    </div>
    <!-- v-if="pay != 0 || couList.free != 1" -->
    <Modal
      class="no-login-box"
      v-model="ModelShop"
      :closable="false"
      :scrollable="true"
      :footer-hide="true"
      :styles="{ top: '300px' }"
      width="300"
    >
      <p class="item-close" @click="ModelShop = false">
        <img src="../assets/images/x.png" alt="" />
      </p>
      <p class="item-img">
        <img src="../assets/images/noShop.png" alt="" />
      </p>
      <p class="item-txt-1">未购买课程</p>

      <p class="item-txt-2">您还未购买课程，请购买后查看课程</p>
      <router-link
        class="item-button"
        :to="{ name: 'OrderInfo', query: queryList }"
        >去购买</router-link
      >
    </Modal>
  </div>
</template>

<script>
import CourseItem from "../components/CourseItem.vue";
import { courseList, courseVideo, getPlayUrl } from "@/api/home";
import { userPlay, addLookRecord } from "@/api/user";
export default {
  data() {
    return {
      ModelShop: false,
      introduce: true,
      curMenu: 0,
      menu: ["课程介绍", "课程目录"],
      courseId: "",
      merchants_id: "",
      teacherId: "",
      videoList: [], //课程视频列表数据
      user: {},
      couList: {},
      queryList: {},
      palyjd: [], //进度
      pay: "", //是否购买 1:是 0:否
      vUrl: "", //视频地址
      curPlayTime: "", //播放事件
      videoId: "",
      coverImg: "", //封面图片
      isPlay: false,
    };
  },
  components: {
    CourseItem,
  },

  created() {},
  mounted() {
    this.courseId = this.$route.query.courseId;
    this.teacherId = this.$route.query.teacherId;
    this.queryList = this.$route.query;
    let user = localStorage.getItem("userInfo");
    this.user = JSON.parse(user);
    this.getcoList();
    this.getVideo();
  },

  methods: {
    // 查看讲师主页
    lookBtn(teacherId) {
      console.log("teacherId", teacherId);
      this.$router.push({
        path: "/RecomDetail",
        query: { teacherId },
      });
    },
    // 后台存观看记录
    editPlay(time) {
      addLookRecord({
        user_id: this.user.id,
        course_id: this.courseId,
        video_id: this.videoId,
        time: this.currentTime,
      }).then((res) => {
        console.log("后台存观看记录", res);
      });
    },

    // 播放视频 存播放时间
    playChange(ev) {
      this.currentTime = ev.path[0].currentTime;
    },
    // 切换视频播放
    videoBtn(v) {
      this.editPlay();
      console.log("v", v);
      this.coverImg = v.coverImg;
      this.videoId = v.videoId;
      getPlayUrl({
        videoId: v.videoId,
        user_name: this.user.id,
        merchants_id: this.user.merchants_id,
      }).then((res) => {
        this.vUrl = res.data;
        console.log("获取播放", res);
      });
    },
    startPlay(v) {
      var videoElement = document.getElementById("videoPlay");
      if (this.couList.free == 0) {
        if (this.pay != 1) {
          console.log(videoElement.pause());
          this.ModelShop = true;
        } else {
          // 开始播放
          // this.isPlay = true;
        }
      } else {
      }
    },
    pausePlay(v) {
      // 暂停播放
      for (const item of this.videoList) {
        if (this.videoId === item.videoId) {
          item.playTime = this.formatTime(this.currentTime);
        }
      }
      this.isPlay = false;
    },
    formatTime(time) {
      // 用户输入任意秒数, 函数计算该毫秒数对应的时分秒, 并返回
      let h = parseInt((time / 60 / 60) % 24);
      h = h < 10 ? "0" + h : h;
      let m = parseInt((time / 60) % 60);
      m = m < 10 ? "0" + m : m;
      let s = parseInt(time % 60);
      s = s < 10 ? "0" + s : s;
      // 作为返回值返回
      return `${h + ":" + m + ":" + s}`;
    },
    // 传入用户输入的数据

    // 获取讲师介绍
    getcoList() {
      courseList({
        courseId: this.courseId,
        merchants_id: this.user.merchants_id,
      }).then((res) => {
        this.couList = res.data;
      });
    },
    // 获取用户观看课程进度
    getPlay() {},
    // 获取课程视频列表数据
    getVideo() {
      courseVideo({ courseId: this.courseId, teacherId: this.teacherId }).then(
        (res) => {
          userPlay({ user_id: this.user.id, course: this.courseId }).then(
            (res1) => {
              this.palyjd = res1.data.list;
              this.pay = res1.data.pay;
              for (const item of res.data) {
                //将观看记录添加到视频列表
                for (const palyItem of this.palyjd) {
                  if (item.videoId === palyItem.video_id) {
                    item.playTime = this.formatTime(palyItem.time);
                  }
                }
              }
              this.videoList = res.data;
              console.log("视频列表数据", this.videoList);
              let videoId = "";
              if (this.palyjd.length == 0) {
                videoId = res.data[0].videoId;
                // console.log("获取课程视频列表数据", this.notPlay);
              } else {
                videoId = res.data[res.data.length - 1].videoId;
              }
              this.videoId = videoId;
              this.getUrl(videoId);
            }
          );
        }
      );
    },
    // 获取视频播放链接
    getUrl(videoId) {
      getPlayUrl({
        user_name: this.user.user_id,
        videoId,
        merchants_id: this.user.merchants_id,
      }).then((res) => {
        this.vUrl = res.data;
      });
    },
  },
  destroyed() {
    this.editPlay(this.curPlayTime);
  },
};
</script>

<style lang="scss" scoped>
.main {
  .m-box {
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(204, 204, 204, 0.2);
    border-radius: 6px;
    border: 1px solid #f0f0f0;
  }
  .m-head {
    height: 600px;
    .mht-video {
      width: 840px;
      height: 480px;
      // background-color: pink;
    }
    .mht-con {
      height: 480px;
      border-radius: 0px 6px 0px 0px;
      background: rgba(0, 0, 0, 0.79);
      overflow: auto;
      & > div {
        border-bottom: 1px solid rgba(226, 226, 226, 0.3);
        opacity: 0.8;
      }
    }
    .mht-con::-webkit-scrollbar {
      width: 4px !important;
      height: 4px !important;
      background-color: #ffffff;
      display: block !important;
    }

    .mht-con::-webkit-scrollbar-track {
      background-color: #484848;
    }

    .mht-con::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #ffa54b;
    }
    .mh-btn {
      width: 200px;
      height: 50px;
      background: #fa9f2f;
      box-shadow: 0px 2px 4px 0px rgba(160, 146, 92, 0.3);
      border-radius: 25px;
    }
  }
  .m-con {
    align-items: flex-start;
    .mc-left {
      width: 820px;
      height: 853px;
      overflow: auto;
    }
    .mcl-ac::after {
      content: "";
      width: 40px;
      height: 4px;
      background: #ff9905;
      border-radius: 2px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    .mcr-border {
      border-bottom: 1px solid #e2e2e2;
    }
    .mht-con {
      & > div {
        border-bottom: 1px solid #e2e2e2;
        opacity: 0.8;
      }
    }
  }
  .coimg {
    width: 100%;
    height: 100%;
  }
}
.no-login-box {
  text-align: center;
  .item-close {
    width: 20px;
    height: 20px;
    position: absolute;
    right: -21px;
    top: -21px;
    cursor: pointer;
  }
  .item-img {
    width: 148px;
    height: 81px;
    margin: 37px auto auto auto;
  }
  .item-txt-1 {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    padding-top: 18px;
  }
  .item-txt-2 {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    padding-top: 6px;
  }
  .item-button {
    display: block;
    width: 250px;
    height: 40px;
    background: #ff9606;
    box-shadow: 0px 2px 6px 0px rgba(192, 106, 0, 0.3);
    border-radius: 20px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 40px;
    margin: 35px auto auto auto;
  }
}
.no-login-box /deep/ .ivu-modal-content {
  position: relative;
  height: 300px;
}
</style>